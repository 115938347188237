var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/atoms/image/Image.html.njk"] = require( "@naturehouse/design-system/components/atoms/image/Image.html.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/atoms/image/Image.macro.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["props"], 
[], 
function (l_props, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("props", l_props);
var t_2 = "";t_2 += "\n\n    ";
var t_3;
t_3 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"id"),"");
frame.set("id", t_3, true);
if(frame.topLevel) {
context.setVariable("id", t_3);
}
if(frame.topLevel) {
context.addExport("id", t_3);
}
t_2 += "\n    ";
var t_4;
t_4 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"src"),"");
frame.set("src", t_4, true);
if(frame.topLevel) {
context.setVariable("src", t_4);
}
if(frame.topLevel) {
context.addExport("src", t_4);
}
t_2 += "\n    ";
var t_5;
t_5 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"srcset"),"");
frame.set("srcset", t_5, true);
if(frame.topLevel) {
context.setVariable("srcset", t_5);
}
if(frame.topLevel) {
context.addExport("srcset", t_5);
}
t_2 += "\n    ";
var t_6;
t_6 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"sizes"),"");
frame.set("sizes", t_6, true);
if(frame.topLevel) {
context.setVariable("sizes", t_6);
}
if(frame.topLevel) {
context.addExport("sizes", t_6);
}
t_2 += "\n    ";
var t_7;
t_7 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"alt"),"");
frame.set("alt", t_7, true);
if(frame.topLevel) {
context.setVariable("alt", t_7);
}
if(frame.topLevel) {
context.addExport("alt", t_7);
}
t_2 += "\n    ";
var t_8;
t_8 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"loading"),false);
frame.set("loading", t_8, true);
if(frame.topLevel) {
context.setVariable("loading", t_8);
}
if(frame.topLevel) {
context.addExport("loading", t_8);
}
t_2 += "\n    ";
var t_9;
t_9 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"width"),0);
frame.set("width", t_9, true);
if(frame.topLevel) {
context.setVariable("width", t_9);
}
if(frame.topLevel) {
context.addExport("width", t_9);
}
t_2 += "\n    ";
var t_10;
t_10 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"height"),0);
frame.set("height", t_10, true);
if(frame.topLevel) {
context.setVariable("height", t_10);
}
if(frame.topLevel) {
context.addExport("height", t_10);
}
t_2 += "\n    ";
var t_11;
t_11 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"objectFit"),"");
frame.set("objectFit", t_11, true);
if(frame.topLevel) {
context.setVariable("objectFit", t_11);
}
if(frame.topLevel) {
context.addExport("objectFit", t_11);
}
t_2 += "\n    ";
var t_12;
t_12 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"container"),false);
frame.set("container", t_12, true);
if(frame.topLevel) {
context.setVariable("container", t_12);
}
if(frame.topLevel) {
context.addExport("container", t_12);
}
t_2 += "\n\n    ";
var t_13;
t_13 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"data"),{});
frame.set("data", t_13, true);
if(frame.topLevel) {
context.setVariable("data", t_13);
}
if(frame.topLevel) {
context.addExport("data", t_13);
}
t_2 += "\n    ";
var t_14;
t_14 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"classes"),[]);
frame.set("classes", t_14, true);
if(frame.topLevel) {
context.setVariable("classes", t_14);
}
if(frame.topLevel) {
context.addExport("classes", t_14);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/atoms/image/Image.html.njk", false, "design-system/v3/components/atoms/image/Image.macro.njk", false, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
callback(null,t_15);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_18,t_17) {
if(t_18) { cb(t_18); return; }
callback(null,t_17);});
});
tasks.push(
function(result, callback){
t_2 += result;
callback(null);
});
env.waterfall(tasks, function(){
t_2 += "\n";
});
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("render");
context.setVariable("render", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/atoms/image/Image.macro.njk"] , dependencies)