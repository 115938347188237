var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/atoms/loader/Loader.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var t_1;
t_1 = env.getFilter("merge").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),[]),["nh-loader"]);
frame.set("classes", t_1, true);
if(frame.topLevel) {
context.setVariable("classes", t_1);
}
if(frame.topLevel) {
context.addExport("classes", t_1);
}
output += "\n\n<nh-loader\n    class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "classes")," "), env.opts.autoescape);
output += "\"\n    ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "animation"),false) == true) {
output += " animation ";
;
}
output += "\n    ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "width"),0) > 0) {
output += " width=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "width"), env.opts.autoescape);
output += "\" ";
;
}
output += "\n    ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "height"),0) > 0) {
output += " height=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "height"), env.opts.autoescape);
output += "\" ";
;
}
output += "\n    ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "rows"),0) > 0) {
output += " rows=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "rows"), env.opts.autoescape);
output += "\" ";
;
}
frame = frame.push();
var t_4 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "data"),{});
if(t_4) {t_4 = runtime.fromIterator(t_4);
var t_2;
if(runtime.isArray(t_4)) {
var t_3 = t_4.length;
for(t_2=0; t_2 < t_4.length; t_2++) {
var t_5 = t_4[t_2][0];
frame.set("[object Object]", t_4[t_2][0]);
var t_6 = t_4[t_2][1];
frame.set("[object Object]", t_4[t_2][1]);
frame.set("loop.index", t_2 + 1);
frame.set("loop.index0", t_2);
frame.set("loop.revindex", t_3 - t_2);
frame.set("loop.revindex0", t_3 - t_2 - 1);
frame.set("loop.first", t_2 === 0);
frame.set("loop.last", t_2 === t_3 - 1);
frame.set("loop.length", t_3);
output += " ";
output += runtime.suppressValue(t_5, env.opts.autoescape);
if(t_6) {
output += "=\"";
output += runtime.suppressValue(t_6, env.opts.autoescape);
output += "\"";
;
}
;
}
} else {
t_2 = -1;
var t_3 = runtime.keys(t_4).length;
for(var t_7 in t_4) {
t_2++;
var t_8 = t_4[t_7];
frame.set("name", t_7);
frame.set("value", t_8);
frame.set("loop.index", t_2 + 1);
frame.set("loop.index0", t_2);
frame.set("loop.revindex", t_3 - t_2);
frame.set("loop.revindex0", t_3 - t_2 - 1);
frame.set("loop.first", t_2 === 0);
frame.set("loop.last", t_2 === t_3 - 1);
frame.set("loop.length", t_3);
output += " ";
output += runtime.suppressValue(t_7, env.opts.autoescape);
if(t_8) {
output += "=\"";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "\"";
;
}
;
}
}
}
frame = frame.pop();
output += "\n/>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/atoms/loader/Loader.html.njk"] , dependencies)