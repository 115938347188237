var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/atoms/loader/Loader.macro.njk"] = require( "@naturehouse/design-system/components/atoms/loader/Loader.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/atoms/image/Image.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var t_1;
t_1 = env.getFilter("merge").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),[]),["nh-image"]);
frame.set("classes", t_1, true);
if(frame.topLevel) {
context.setVariable("classes", t_1);
}
if(frame.topLevel) {
context.addExport("classes", t_1);
}
var t_2;
t_2 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "container"),false) == true;
frame.set("use_container", t_2, true);
if(frame.topLevel) {
context.setVariable("use_container", t_2);
}
if(frame.topLevel) {
context.addExport("use_container", t_2);
}
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "use_container") == true) {
output += "\n<div class=\"nh-image-container\">\n";
;
}
output += "\n    <img\n        is=\"nh-image\"\n        class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "classes")," "), env.opts.autoescape);
output += "\"";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "src"),"") != "") {
output += "src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "src"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "alt"),"") != "") {
output += "alt=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "alt"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "id"),"") != "") {
output += " id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "id"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "srcset"),"") != "") {
output += " srcset=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcset"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "sizes"),"") != "") {
output += " sizes=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "loading"),"") != "") {
output += " loading=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "loading"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "width"),0) > 0) {
output += " width=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "width"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "height"),0) > 0) {
output += " height=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "height"), env.opts.autoescape);
output += "\"";
;
}
frame = frame.push();
var t_5 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "data"),{});
if(t_5) {t_5 = runtime.fromIterator(t_5);
var t_3;
if(runtime.isArray(t_5)) {
var t_4 = t_5.length;
for(t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3][0];
frame.set("[object Object]", t_5[t_3][0]);
var t_7 = t_5[t_3][1];
frame.set("[object Object]", t_5[t_3][1]);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
output += " ";
output += runtime.suppressValue(t_6, env.opts.autoescape);
if(t_7) {
output += "=\"";
output += runtime.suppressValue(t_7, env.opts.autoescape);
output += "\"";
;
}
;
}
} else {
t_3 = -1;
var t_4 = runtime.keys(t_5).length;
for(var t_8 in t_5) {
t_3++;
var t_9 = t_5[t_8];
frame.set("name", t_8);
frame.set("value", t_9);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
output += " ";
output += runtime.suppressValue(t_8, env.opts.autoescape);
if(t_9) {
output += "=\"";
output += runtime.suppressValue(t_9, env.opts.autoescape);
output += "\"";
;
}
;
}
}
}
frame = frame.pop();
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "objectFit"),"") != "") {
output += " style=\"--nh-image-object-fit: ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "objectFit"), env.opts.autoescape);
output += "\"";
;
}
output += "\n    />\n    ";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "src"),"") != "") {
output += "\n        ";
env.getTemplate("@naturehouse/design-system/components/atoms/loader/Loader.macro.njk", false, "design-system/v3/components/atoms/image/Image.html.njk", false, function(t_11,t_10) {
if(t_11) { cb(t_11); return; }
t_10.getExported(function(t_12,t_10) {
if(t_12) { cb(t_12); return; }
context.setVariable("loaderTemplate", t_10);
output += "\n        ";
output += runtime.suppressValue((lineno = 22, colno = 32, runtime.callWrap(runtime.memberLookup((t_10),"render"), "loaderTemplate[\"render\"]", context, [{"classes": ["nh-loader--absolute"],"width": env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "width"),0),"height": env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "height"),0),"animation": true}])), env.opts.autoescape);
output += "\n    ";
})});
}
output += "\n";
if(runtime.contextOrFrameLookup(context, frame, "use_container") == true) {
output += "\n</div>\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/atoms/image/Image.html.njk"] , dependencies)